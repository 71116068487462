import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firebaseConfig } from "./config";
import { getAuth } from "firebase/auth";

//stage
// const firebaseConfig = {
//     apiKey: "AIzaSyBH9guWF3RycDdLyVGUiuFmfwp-aQGIs_Y",
//     authDomain: "firebase.squadio-fe-stage.ibtik.com",
//     projectId: "squadio-portal-4ce92",
//     storageBucket: "squadio-portal-4ce92.appspot.com",
//     messagingSenderId: "451089176824",
//     appId: "1:451089176824:web:0b27f562a2ee16d1d3430d",
//     measurementId: "G-E2WDMNPHE8"
//   }

//production
// const firebaseConfig = {
//     apiKey: "AIzaSyDOi2ulfy9H7N0v5hprsriCNIfafai9AvM",
//     authDomain: "squadio-portal-b3b17.firebaseapp.com",
//     projectId: "squadio-portal-b3b17",
//     storageBucket: "squadio-portal-b3b17.appspot.com",
//     messagingSenderId: "563105078034",
//     appId: "1:563105078034:web:0d7ed742a85386eb1371cb",
//     measurementId: "G-G-R8VL8BTH30"
//   }
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = getAuth();
