
  
export const firebaseConfig={

    apiKey:process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain:process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId:process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket:process.env.VUE_APP_FIREBASE_STORAGE_BUKET,
  messagingSenderId:process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId:process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId:process.env.VUE_APP_FIREBASE_MESURMENT_ID

}