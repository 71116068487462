import "izitoast/dist/css/iziToast.min.css";
import iZtoast from "izitoast";
import './style.scss';
const toast = {
    error: (message, title = "") => {
        return iZtoast.error({
            title: title,
            message: message,
            icon: "",
            position: "center",
            messageSize: 18,
            messageLineHeight: 30,
            color: "#FF6F59",
            transitionIn: 'fadeInUp',
            messageColor: 'white',
            progressBar: false,
        });
    },
    success: (message, title = "") => {
        return iZtoast.success({
            title: title,
            message: message,
            icon: "",
            position: "center",
            messageSize: 18,
            messageLineHeight: 30,
            color: "#3BD4AE",
            messageColor: 'white',
            progressBar: false,
        });
    }
};

export default toast;
