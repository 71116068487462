import {
  isClient,
  isTalent,
  isLoggedIn,
  getProfileData,
} from "../utils/shared";
import store from "../store";

export const routes = [
  {
    path: "/",
    redirect: "/home/client",
  },
  {
    path: "/home/:type",
    name: "home",
    component: () => import("../layouts/landingLayout/landingLayout.vue"),
    meta: {
      title: "Squadio | Build your team",
    },
    beforeEnter(to, from, next) {
      if (isClient() && isLoggedIn()) {
        if (to.params.type == "client") {
          store
            .dispatch("auth/setClientCounters")
            .then(() => {
              next();
            })
            .catch(() => {
              next("/404");
            });
        }
      } else {
        next();
      }
    },
  },
  {
    path: "/bring-buddy",
    name: "bring-buddy",
    component: () => import("../pages/bringBuddy/bringBuddy.vue"),
    meta: {
      title: "Squadio | Bring your buddy",
    },
  },
  /*   {
    path: "/code-challenge",
    component: () => import("../pages/codeChallenge/home/home.vue"),
    meta: {
      title: "Squadio | Code Challenge",
    },
  }, */
  // {
  //   path: "/talent/landing",
  //   component: () => import("../pages/Talent/Home/index.vue"),
  //   name: "talent-home",
  //   meta: {
  //     title: "Squadio",
  //   },
  // },
  /* {
    path: "/",
    component: () => import("../layouts/auth.vue"),
    children: [
      {
        path: "signUp",
        component: () => import("../pages/signup/signup.vue"),
        name: "Signup",
        meta: {
          authPage: true,
          title: "Sign up | Squadio",
        },
      },
       {
        path: "--login",
        component: () => import("../pages/login/login.vue"),
        name: "Login",
        meta: {
          authPage: true,
          title: "Log in | Squadio",
        },
      },
    ],
  }, */
  {
    path: "/signUp",
    name: "signUp",
    beforeEnter(to, from, next) {
      if (to.fullPath == "/signUp") {
        next("client-signup");
      } else if (to.fullPath.includes("/signUp?type=1")) {
        if (to.query.Campaign) {
          next("talent-signup?Campaign=" + to.query.Campaign);
        } else {
          next("talent-signup");
        }
      } else if (to.fullPath.includes("/signUp?type=2")) {
        if (to.query.Campaign) {
          next("client-signup?Campaign=" + to.query.Campaign);
        } else {
          next("client-signup");
        }
      } else {
        next("404");
      }
    },
  },
  {
    path: "/talent/landing",
    redirect: "home/talent",
  },
  {
    path: "/",
    component: () => import("../layouts/signup.vue"),
    children: [
      {
        path: "login",
        component: () => import("../pages/login/new-login.vue"),
        name: "Login",
        meta: {
          authPage: true,
          title: "Log in | Squadio",
        },
      },
      {
        path: "talent-signup",
        component: () => import("../pages/signup/talentSignup.vue"),
        name: "talent-signup",
        meta: {
          authPage: true,
          title: "Talent Sign Up | Squadio",
        },
      },
      {
        path: "client-signup",
        component: () => import("../pages/signup/clientSignup.vue"),
        name: "client-signup",
        meta: {
          authPage: true,
          title: "Client Sign Up | Squadio",
        },
      },
    ],
  },
  {
    path: "/case-study",
    component: () => import("../pages/Client/caseStady/index.vue"),
    name: "case-study",
    meta: {
      title: "Aanaab Case Study | Squadio",
    },
  },
  {
    path: "/",
    component: () =>
      import("../layouts/logedinTalentLayout/logedinTalentLayout.vue"),
    beforeEnter(to, from, next) {
      if (!isLoggedIn()) {
        localStorage.setItem("redirect", to.fullPath);
        next("/login");
      } else {
        if (isTalent()) {
          if (to.name == "unsubscribe") {
            store.dispatch("talentProfile/getProfileDataRequest").then(() => {
              if (to.params.id == store.getters["auth/getUser"].id) {
                next();
              } else {
                next("/404");
              }
            });
          } else {
            store
              .dispatch("talentProfile/subscription", to.query.subscription)
              .then(() => {
                localStorage.removeItem("redirect");
                next();
              })
              .catch(() => {
                next("/404");
              });
          }
        } else if (isClient) {
          console.log();
        } else {
          next("/404");
        }
      }
    },
    children: [
      {
        path: "/subscribe",
        component: () =>
          import("../pages/Talent/subscribe/submit-subscribe.vue"),
        name: "subscribe",
        meta: {
          title: "Subscribe | Squadio",
        },
      },
      {
        path: "/unsubscribe/:id",
        component: () => import("../pages/Talent/unsubscribe/unsubscribe.vue"),
        name: "unsubscribe",
        meta: {
          title: "Unsubscribe | Squadio",
        },
      },
      {
        path: "/submit-subscribe/",
        component: () =>
          import("../pages/Talent/subscribe/submit-subscribe.vue"),
        name: "submit-subscribe",
        meta: {
          title: "Subscribe | Squadio",
        },
      },
    ],
  },
  //for talent home page & wizard
  {
    path: "/talent",
    component: () => import("../layouts/Talent/talent.vue"),
    beforeEnter(to, from, next) {
      if (isTalent()) {
        next();
      } else {
        next("/404");
      }
    },
    children: [
      {
        path: "wizard",
        component: () => import("../pages/wizard/wizard.vue"),
        name: "Wizard",
        meta: {
          requiresAuth: true,
          title: "Apply as a talent flow | Squadio",
        },
      },
      {
        path: "home",
        component: () => import("../pages/Talent/Home/index.vue"),
        name: "talentHome",
        meta: {
          requiresAuth: true,
          title: "Squadio",
        },
      },
    ],
  },
  //for logged in talent profile
  {
    path: "/sq/talent", //sq stands for Squadio (just static naming)
    redirect: "/sq/talent/profile",
    component: () =>
      import("../layouts/logedinTalentLayout/logedinTalentLayout.vue"),
    children: [
      {
        path: "profile",

        component: () => import("../pages/Talent/profile/profile.vue"),
        name: "Profile",
        meta: {
          requiresAuth: true,
          title: "Profile | Squadio",
        },
        beforeEnter(to, from, next) {
          if (isTalent()) {
            next();
          } else {
            next("/404");
          }
        },
      },
      {
        path: "editProfile",
        component: () =>
          import(
            "../pages/Talent/profile/components/editPersonalInfo/editPersonalInfo.vue"
          ),
        name: "profile",
        meta: {
          requiresAuth: true,
          title: "Profile | Squadio",
        },
        beforeEnter(to, from, next) {
          if (isTalent()) {
            next();
          } else {
            next("/404");
          }
        },
      },
      {
        path: "report",
        component: () =>
          import(
            "../pages/Talent/profile/components/assessments/assessmentReport.vue"
          ),
        name: "report",
        meta: {
          requiresAuth: true,
          title: "Report | Squadio",
        },
        beforeEnter(to, from, next) {
          if (isTalent()) {
            next();
          } else {
            next("/404");
          }
        },
      },
      {
        path: "contracts-renewal",
        component: () => import("../pages/Talent/ContractsRenewal"),
        name: "contractsRenewal",
        meta: {
          requiresAuth: true,
          title: "Squadio | Contracts Renewal",
        },
        beforeEnter(to, from, next) {
          store
            .dispatch("auth/setTalentHasContractRenewal")
            .then(() => {
              if (isTalent()) {
                if (store.getters["auth/getTalentHasContractRenewal"]) {
                  next();
                } else {
                  next("/404");
                }
              } else {
                next("/404");
              }
            })
            .catch(() => {
              next("/404");
            });
        },
      },
    ],
  },
  {
    path: "/client",
    component: () => import("../layouts/Client/client.vue"),
    children: [
      {
        path: "wizard",
        component: () => import("../pages/Client/wizard/index.vue"),
        name: "clientWizard",
        meta: {
          requiresAuth: false,
          title: "Squadio | Build your team",
        },
        beforeEnter(to, from, next) {
          if (isClient() || !isTalent()) {
            next();
          } else {
            next("/404");
          }
        },
      },
      {
        path: "home",
        component: () => import("../pages/Client/home/index.vue"),
        name: "clientHome",
        meta: {
          requiresAuth: true,
          title: "Squadio",
        },
        beforeEnter(to, from, next) {
          if (!isLoggedIn()) {
            localStorage.setItem("redirect", to.fullPath);
            next("/login");
          } else {
            if (isClient()) {
              next();
            } else {
              next("/404");
            }
          }
        },
      },
      {
        path: "profile",
        component: () => import("../pages/Client/clientProfile/index.vue"),
        name: "clientProfile",
        meta: {
          requiresAuth: true,
          title: "Apply as a client flow | Squadio",
        },
        beforeEnter(to, from, next) {
          if (isClient()) {
            next();
          } else {
            next("/404");
          }
        },
      },
    ],
  },
  {
    path: "/sq/client", //sq stands for Squadio (just static naming)
    component: () => import("../layouts/logedinClientLayout/logedinClientLayout.vue"),
    beforeEnter(to, from, next) {
      if (isLoggedIn() && isClient()) {
        store
          .dispatch("auth/setClientCounters")
          .then(() => {
            if (isClient()) {
              next();
            } else {
              next("/404");
            }
          })
          .catch(() => {
            next("/404");
          });
      } else {
        next();
      }
    },
    children: [
      {
        path: "matching",
        component: () => import("../pages/Client/matching/matching.vue"),
        name: "clientMatching",
        meta: {
          requiresAuth: true,
          title: "Squadio | Matched Talents",
        },
        beforeEnter(to, from, next) {
          if (isClient()) {
            if (localStorage.getItem("step") > 5) {
              next();
            } else {
              next(`/client/wizard`);
            }
          } else {
            next("/404");
          }
        },
      },
      {
        path: "contracts-renewal",
        component: () => import("../pages/Client/contractsRenewal"),
        name: "clientContractsRenewal",
        meta: {
          requiresAuth: true,
          title: "Squadio | Contracts Renewal",
        },
        beforeEnter(to, from, next) {
          if (isClient()) {
            if (store.getters["auth/getClientHasContractRenewal"]) {
              next();
            } else {
              next(`/client/wizard`);
            }
          } else {
            next("/404");
          }
        },
      },
      {
        path: "interviews-list",
        component: () => import("../pages/Client/interviewsList"),
        name: "interviewsList",
        meta: {
          requiresAuth: true,
          title: "Squadio | Interviews list",
        },
        beforeEnter(to, from, next) {
          if (isClient()) {
            if (store.getters["auth/getClientHasInterviews"]) {
              next();
            } else {
              next(`/client/wizard`);
            }
          } else {
            next("/404");
          }
        },
      },
      {
        path: "feedback-form",
        component: () => import("../pages/Client/feedbackForm"),
        name: "feedbackForm",
        meta: {
          requiresAuth: true,
          title: "Squadio | Feedback form",
        },
        beforeEnter(to, from, next) {
          const actions = ["accepted", "rejected"];
          if (
            isClient() &&
            actions.includes(to.query.action) &&
            to.query.selection
          ) {
            next();
          } else {
            next(`404`);
          }
        },
      },
      {
        path: "feedback-form",
        component: () => import("../pages/Client/feedbackForm"),
        name: "feedbackForm",
        meta: {
          requiresAuth: true,
          title: "Squadio | Feedback form",
        },
        beforeEnter(to, from, next) {
          const actions = ["accepted", "rejected"];
          if (isClient() && actions.includes(to.query.action) && to.query.selection) {
            next();
          } else {
            next(`404`)
          }
        },
      },
      {
        path: "talent-profile/:id",
        component: () =>
          import(
            "../pages/Client/clientViewTalentProfile/newProfile.vue"
            //  clientViewTalentProfile
          ),
        name: "clientViewTalent",
        meta: {
          requiresAuth: false,
          title: "Client Matching | Squadio",
        },
        beforeEnter(to, from, next) {
          getProfileData(to.params.id)
            .then(() => {
              next();
            })
            .catch((error) => {
              if (
                error?.response?.status == 403 ||
                error?.response?.status == 404
              ) {
                if (
                  error.response.data.message ===
                    "You are not authorized for this request." ||
                  error.response.data.message ===
                    "Nomination has been Expired, please contact an account manager for any inquiries"
                ) {
                  next("/404?nomination=expired");
                } else {
                  next("/404");
                }
              } else {
                next("/404");
              }
            });
        },
      },
      {
        path: "assessment-report/:id",
        component: () => import("../pages/Client/assessmentReport/index.vue"),
        name: "assessmentReport",
        meta: {
          requiresAuth: false,
          title: "Assessment Report | Squadio",
        },
      },
      /*       {
        path: "new-talent-profile/:id",
        component: () =>
          import("../pages/Client/clientViewTalentProfile/newProfile.vue"),
        name: "clientViewTalent",
        meta: {
          requiresAuth: false,
          title: "Client Matching | Squadio",
        },
      }, */
      {
        path: "talent-profile/assessment/:id",
        component: () =>
          import(
            "../pages/Client/clientViewTalentAssessment/clientViewTalentAssessment.vue"
          ),
        name: "client-view-talent",
        meta: {
          requiresAuth: false,
          title: "Client Matching | Squadio",
        },
        beforeEnter(to, from, next) {
          if (isClient()) {
            next();
          } else {
            next("/404");
          }
        },
      },
      {
        path: "nomination",
        component: () => import("../pages/Client/nominatedProfiles/index.vue"),
        name: "Nominated profiles",
        meta: {
          requiresAuth: true,
          title: "Squadio | Nominated Profiles",
        },
        beforeEnter(to, from, next) {
          if (!isLoggedIn()) {
            localStorage.setItem("redirect", to.fullPath);
            next("/login");
          } else {
            if (isClient() && store.getters["auth/getClientNominationState"]) {
              next();
            } else {
              next("/404");
            }
          }
        },
      },
    ],
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("../pages/Errors/notFound.vue"),
  },
  { path: "*", redirect: "/404" },
];
