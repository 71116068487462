<template>
  <div>
    <!-- Your component's template goes here -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      scriptSrc:
        "https://static.zdassets.com/ekr/snippet.js?key=a9576308-b71f-4b1e-9be4-b37d9caf09ad",
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.setAttribute("id", "ze-snippet");
    script.setAttribute("src", this.scriptSrc);
    document.head.appendChild(script);
    localStorage.setItem("zE", true);

    script.onload = function () {
      if (window.zE)  window.zE("messenger", "show");
    };
  },
};
</script>